<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" alt="logo" contain class="me-3"></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              alt="mask"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="
                  require(`@/assets/images/3d-characters/illustration-forgot-password-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                  }.png`)
                "
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Forgot Password? 🔒</p>
                  <p class="mb-2">Enter your email and we'll send you instructions to reset your password</p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="forgotPasswordForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="john@example.com"
                      id="email-field"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-4"
                    ></v-text-field>

                    <v-btn class="send-reset-link" block color="primary" @click="handleFormSubmit"> Send reset link </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm back-to-login">
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js';
import { ref } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { emailValidator, required } from '@core/utils/validation';
import { useRouter } from '@core/utils';
import store from '@/store';
import handleError from '@/plugins/handle-error';
import Vue from 'vue';

export default {
  setup() {
    const isPasswordVisible = ref(false);
    const email = ref('');
    const errorMessages = ref({ email: null });

    const forgotPasswordForm = ref(null);
    const { router } = useRouter();

    const handleFormSubmit = () => {
      const isFormValid = forgotPasswordForm.value.validate();

      if (!isFormValid) return;

      store
        .dispatch('auth/forgotPassword', { email: email.value })
        .then((response) => {
          router.replace({ name: 'auth-login' });
          Vue.notify({
            type: 'success',
            title: 'Forgot password',
            text: response.data,
            duration: 10000,
          });
        })
        .catch(error => (errorMessages.value = { email: handleError(error) }));
    };

    return {
      isPasswordVisible,
      forgotPasswordForm,
      email,
      errorMessages,
      handleFormSubmit,
      validators: {
        required,
        emailValidator,
      },
      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
}
